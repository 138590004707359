import React from 'react';
import FoodForest from './components/FoodForest';
import forestImage from './images/forest.jpg';

function App() {
  return (
    <div className="App">
      <FoodForest forestImage={forestImage} />
    </div>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import forestImage from '../images/forest.jpg';

const FoodForest = () => {
  const [impactStats, setImpactStats] = useState({
    treesPlanted: 0,
    communitiesEngaged: 0,
    countriesReached: 0
  });

  const [chartData, setChartData] = useState([]);
  const [activeSection, setActiveSection] = useState('hero');
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      setImpactStats({
        treesPlanted: 10000,
        communitiesEngaged: 50,
        countriesReached: 15
      });

      setChartData([
        { name: 'Jan', trees: 2000 },
        { name: 'Feb', trees: 3000 },
        { name: 'Mar', trees: 4500 },
        { name: 'Apr', trees: 6000 },
        { name: 'May', trees: 8000 },
        { name: 'Jun', trees: 10000 },
        { name: 'Jul', trees: 12000 },
        { name: 'Aug', trees: 14000 },
        { name: 'Sep', trees: 16000 },
        { name: 'Oct', trees: 18000 },
        { name: 'Nov', trees: 20000 },
        { name: 'Dec', trees: 22000 },
      ]);
    };

    fetchData();

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = ['hero', 'mission', 'impact', 'growth'];
      
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section && scrollPosition >= section.offsetTop - 200) {
          setActiveSection(sections[i]);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    const timer = setTimeout(() => setShowComingSoon(true), 500);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  const parallaxBg = {
    backgroundImage: `url(${forestImage})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const dropdownItems = {
    'About Us': ['Our Story', 'Team', 'Partners'],
    'Our Projects': ['Current Projects', 'Past Projects', 'Future Initiatives'],
    'Get Involved': ['Volunteer', 'Donate', 'Corporate Partnerships'],
    'News & Blog': ['Latest News', 'Blog Posts', 'Press Releases'],
    'Contact Us': ['General Inquiries', 'Media Relations', 'Career Opportunities']
  };

  const handleDropdownHover = (dropdownName) => {
    setActiveDropdown(dropdownName);
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null);
  };

  return (
    <div className="min-h-screen bg-green-50 text-gray-800 font-sans overflow-x-hidden">
      <header className="fixed w-full bg-green-700 text-white p-4 z-50">
        <nav className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold"><a href="/">FoodForest.ing</a></h1>
          <ul className="flex space-x-4">
            <li><a href="/" className="hover:underline">Home</a></li>
            {Object.keys(dropdownItems).map((item) => (
              <li key={item} 
                  className="relative"
                  onMouseEnter={() => handleDropdownHover(item)}
                  onMouseLeave={handleDropdownLeave}>
                <a href={`/${item.toLowerCase().replace(/\s+/g, '-')}`} className="hover:underline">
                  {item}
                </a>
                {activeDropdown === item && (
                  <ul className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black bg-opacity-75 ring-1 ring-black ring-opacity-5">
                    {dropdownItems[item].map((subItem) => (
                      <li key={subItem}>
                        <a href="#" className="block px-4 py-2 text-sm text-white hover:bg-green-600">
                          {subItem}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <main>
        <section id="hero" className="h-screen flex items-center justify-center text-white relative overflow-hidden" style={parallaxBg}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="text-center relative z-10">
            <h1 className="text-5xl font-bold mb-4">Cultivating Abundance, Nurturing the Earth</h1>
            <p className="text-xl mb-8">Join us in creating a global network of food forests to combat climate change, increase food sovereignty, and nourish communities.</p>
            <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 transform hover:scale-105">
              Donate Now
            </button>
            <div className={`mt-8 text-3xl font-bold transition-opacity duration-1000 ${showComingSoon ? 'opacity-100' : 'opacity-0'}`}>
              Coming Soon
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-green-700 to-transparent"></div>
        </section>

        <section id="mission" className="py-20 bg-white relative">
          <div className="container mx-auto text-center relative z-10">
            <h2 className="text-4xl font-bold mb-6 text-green-700">
              Our Mission
            </h2>
            <p className="text-xl max-w-2xl mx-auto">
              We envision a world where food forests thrive in every community, providing sustainable nourishment and restoring ecological balance.
            </p>
          </div>
        </section>

        <section id="impact" className="py-20 bg-green-100 relative overflow-hidden">
          <div className="container mx-auto text-center relative z-10">
            <h2 className="text-4xl font-bold mb-10 text-green-700">
              Our Impact
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {Object.entries(impactStats).map(([key, value]) => (
                <div key={key} className="bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
                  <h3 className="text-5xl font-bold text-green-600 mb-4">
                    {value.toLocaleString()}
                  </h3>
                  <p className="text-xl capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="growth" className="py-20 bg-white relative">
          <div className="container mx-auto relative z-10">
            <h2 className="text-4xl font-bold mb-10 text-center text-green-700">
              Our Growth
            </h2>
            <div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="trees" stroke="#22c55e" strokeWidth={3} dot={{ r: 6 }} activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-green-800 text-white py-12">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-2xl font-bold mb-6">Our Sponsors & Partners</h3>
            <div className="flex space-x-6">
              <img src="/api/placeholder/120/60" alt="Sponsor 1 Logo" className="h-16 rounded-lg shadow-md transition duration-300 transform hover:scale-105" />
              <img src="/api/placeholder/120/60" alt="Sponsor 2 Logo" className="h-16 rounded-lg shadow-md transition duration-300 transform hover:scale-105" />
            </div>
          </div>
          <div>
            <p className="text-lg">&copy; 2024 FoodForest.ing</p>
            <ul className="mt-4 space-y-2">
              <li>
                <a href="mailto:info@foodforest.ing" className="hover:underline flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                  info@foodforest.ing
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 0C4.477 0 0 4.477 0 10c0 4.411 2.865 8.138 6.839 9.465.5.092.682-.217.682-.482 0-.237-.009-.866-.013-1.7-2.782.603-3.369-1.34-3.369-1.34-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.831.092-.646.35-1.086.636-1.336-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.268 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.026 2.747-1.026.546 1.377.203 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.935.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C17.138 18.135 20 14.41 20 10c0-5.523-4.477-10-10-10z" clipRule="evenodd" />
                  </svg>
                  Social Media Links
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FoodForest;